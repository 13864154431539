/*@import '~reset-css/sass/reset';*/
@import "~bootstrap/dist/css/bootstrap.css";
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~highlight.js/styles/github.css';

.table th, .table td {
 padding: 0;
}


.clickable {
    cursor: pointer;
}

.modal-lg {
    max-width: 45%;
}

.modal-dialog {
    overflow-y: initial !important;
}

.modal-body {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
